<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>


            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>

            <v-dialog v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="mx-11"
                            color="red"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >New
                    </v-btn>
                </template>

                <v-card v-if="dialogAddItem">
                    <v-toolbar
                            color="blue-grey"
                            dark
                    >
                        <v-btn
                                dark
                                icon
                                @click="dialogAddItem = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    :disabled="(!valid || buttonLoading)"
                                    :loading="buttonLoading"
                                    dark
                                    text
                                    @click="validate"
                            >Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>


                    <v-alert
                            v-if="Object.keys(errors).length>0"
                            dense
                            text
                            type="error"
                    >
                        <h4 class="subtitle">Correct the following errors:</h4>
                        <ul>
                            <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                            >{{ error.message || error }}
                            </li>
                        </ul>
                    </v-alert>


                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="name" :counter="150" :rules="nameRules" clearable label="job category/type name"
                                                      outlined required></v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isTypeCategory"
                                                color="red"
                                                label="is Type Category ?"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isActive"
                                                color="red"
                                                label="show job category/type in the list"
                                        ></v-checkbox>
                                    </v-col>


                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>

        </v-card-title>


        <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                :loading="loading"
                :options.sync="options"
                :search="search"
                class="elevation-1"
        >


            <template v-slot:item.actions="{ item }">
                <v-icon
                        class="mr-3"
                        color="blue"
                        small
                        @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        color="red"
                        small
                        @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>


            <template v-slot:item.isActive="{ item }">
                <v-icon :color="(item.isActive)?'green':'red'">
                    mdi-eye
                </v-icon>
            </template>

            <template v-slot:item.isTypeCategory="{ item }">
                <v-chip color="orange" dark>{{ item.isTypeCategory ? "Job Category" : "Job Type" }}</v-chip>
            </template>

            <template v-slot:item.name="{ item }">
                <v-chip dark>{{ item.name }}</v-chip>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {urlCreateJobCategory, urlJobCategories, urlUpdateJobCategory} from "@/config";

export default {
    data: () => ({
        valid: true,
        search: '',
        id: 0,
        name: '',
        nameRules: [
            v => !!v || 'name is required',
            v => (v && v.length <= 150) || 'name must be less than 150 characters',
        ],
        isTypeCategory: true,
        isActive: true,
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,

        title: "Job Types and Categories",

        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: 'Name', value: 'name', align: 'center'},
            {text: 'Type', value: 'isTypeCategory', align: 'center'},
            {text: 'Status', value: 'isActive', align: 'center'},
            {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
        ],
        isFormTitleEdit: false,


    }),

    created() {
        this.getJobCategoriesFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit job category' : 'Add new job category'
        },
    },

    watch: {


        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },


    },

    methods: {
        getJobCategoriesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlJobCategories)
                .then(response => {

                    this.desserts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },


        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {
            let data = {
                name: this.name,
                isActive: this.isActive,
                isTypeCategory: this.isTypeCategory,
            };


            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateJobCategory + this.id, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getJobCategoriesFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {

                networks.fetchFromWeb(urlCreateJobCategory, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getJobCategoriesFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.name = '';
            this.id = 0;
            this.isActive = true;
            this.isTypeCategory = true;
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {
            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.name = item.name;
            this.isTypeCategory = item.isTypeCategory;
            this.isActive = item.isActive;
            this.dialogAddItem = true;
        },

        deleteItem(item) {
            const index = this.desserts.indexOf(item);
            confirm('do you want to delete this ?') && this.desserts.splice(index, 1)
        },


    },
}
</script>



